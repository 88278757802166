import React, { useEffect, useRef, useState } from "react";
import "./contactslist.scss";
import Button from "../button/button";
import { Modal } from "react-bootstrap";
import Inputdash from "../Inputdash/inputdash";
import axios from "axios";
import { toast } from "react-toastify";
import Papa from "papaparse";

function Contactslist() {
  const Apiurl = process.env.REACT_APP_API_KEY;
  const [selectagent, setSelectagent] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [show, setShow] = useState(false);
  const [listName, setListName] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [deletemodal, setDeletemodal] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [loader, setLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Search state for lists
  const [contactSearchTerm, setContactSearchTerm] = useState(""); // Search state for contacts in modal
  const [csvModal, setCsvModal] = useState(false);
  const [csvData, setCsvData] = useState([]); // CSV data state
  const [csvSearchTerm, setCsvSearchTerm] = useState(""); // CSV search state
  const [fileInput, setFileInput] = useState(null);
  const [btnLoader, setbtnLoader] = useState(false);
  const handleClose = () => setShow(false);
  const handleListClose = () => {
    setListName(false);
    setShow(true);
  };
  const handledeleteclose = () => {
    setDeleteid("");
    setDeletemodal(false);
  };
  // Handle file input when CSV button is clicked
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      parseCSV(file);
    }
  };

  // Parse CSV file
  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const parsedData = results.data;

        // Validate CSV columns (email, name, or phone should be present)
        const hasRequiredFields = parsedData.some(
          (row) =>
            row.hasOwnProperty("email") ||
            row.hasOwnProperty("Email") ||
            row.hasOwnProperty("name") ||
            row.hasOwnProperty("Name") ||
            row.hasOwnProperty("phone") ||
            row.hasOwnProperty("Phone")
        );

        if (hasRequiredFields) {
          setCsvData(parsedData);
          setContactModal(false);
          setCsvModal(true); // Open CSV modal with parsed data
        } else {
          toast.error(
            "CSV file must contain at least email, name, or phone fields."
          );
        }
      },
      error: (error) => {
        toast.error("Failed to parse CSV file");
      },
    });
  };

  // Filtered data for CSV modal
  const filteredCsvData = csvData.filter(
    (item) =>
      item.name?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
      item.Name?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
      item.email?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
      item.Email?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
      item.phone?.toLowerCase().includes(csvSearchTerm.toLowerCase()) ||
      item.Phone?.toLowerCase().includes(csvSearchTerm.toLowerCase())
  );
  const handledeleteshow = () => {
    setDeletemodal(true);
  };
  const handleListShow = () => {
    if (contacts.length === 0) {
      toast.error("Please select at least one contact", { toastId: "empty" });
      return;
    }
    setListName(true);
  };

  // List create API
  const listApi = () => {
    if (!fileName) {
      toast.error("Please add name", { toastId: "empty" });
      return;
    } else {
      setbtnLoader(true);
      const options = {
        method: "POST",
        url: `${Apiurl}/contacts`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: {
          fileName: fileName,
          lists: contacts,
        },
      };
      axios
        .request(options)
        .then(function (response) {
          handleListClose();
          setListName(false);
          setContactModal(false);
          setCsvModal(false);
          setContacts([]);
          getListApi();
          setbtnLoader(false);
        })
        .catch(function (error) {
          toast.error(error, { toastId: "listerror" });
          setbtnLoader(false);
        });
    }
  };

  // All lists API
  const getListApi = () => {
    setDataLoader(true);
    const options = {
      method: "GET",
      url: `${Apiurl}/getallcontacts`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setData(response.data);
        setDataLoader(false);
      })
      .catch(function (error) {
        setDataLoader(false);
      });
  };

  // Delete List API
  const deleteList = () => {
    setLoader(true);
    const options = {
      method: "DELETE",
      url: `${Apiurl}/contacts/${deleteid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .request(options)
      .then(function () {
        getListApi();
        setLoader(false);
        toast.success("List Deleted Successfully", { toastId: "deletelist" });
        handledeleteclose();
      })
      .catch(function (error) {
        toast.error(error);
        setLoader(false);
      });
  };

  // Contacts API
  const [allContacts, setAllContacts] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchContacts = async () => {
    setError(null);
    setLoading(true);

    try {
      const requestBody = nextPageToken ? { after: nextPageToken } : {};
      const options = {
        method: "POST",
        url: `${Apiurl}/getContactFromHupspot`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: requestBody,
      };
      const response = await axios.request(options);

      setAllContacts((prevContacts) => [
        ...prevContacts,
        ...response.data.data,
      ]);

      setNextPageToken(response.data.nextPageToken || null);
    } catch (err) {
      setError("Failed to fetch contacts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
    getListApi();
  }, []);

  const modalRef = useRef(null);

  // Separate filtering for lists and contacts
  const filteredData = data.filter((item) =>
    item.fileName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredContacts = allContacts.filter((item) => {
    const firstName = item?.properties?.firstname || ""; // Default to empty string if null
    const lastName = item?.properties?.lastname || ""; // Default to empty string if null
    const email = item?.properties?.email || ""; // Default to empty string if null

    return (
      firstName.toLowerCase().includes(contactSearchTerm.toLowerCase()) ||
      lastName.toLowerCase().includes(contactSearchTerm.toLowerCase()) ||
      email.toLowerCase().includes(contactSearchTerm.toLowerCase())
    );
  });

  return (
    <div className="dash-main">
      <section className="contactlist-sec">
        <div className="head">
          <h3 className="heading primarycolor Carmen-semibold-font">Lists</h3>
          <Button
            text="Create new list"
            className="primary-button w-100 fs-16"
            maxWidth="160px"
            width="100%"
            padding="10px 16px"
            onClick={() => {
              setContactModal(true);
            }}
          />
        </div>
        <div className="contacts">
          <div className="inner-top d-flex align-center justify-content-between gap-4">
            <div>
              <p className="greycolor Carmen-medium-font">All Lists</p>
            </div>
          </div>
          <div className="contacts-inner">
            <div className="topbar">
              <div className="search-bar">
                <img
                  src="/assets/icons/search.svg"
                  alt="Search icon"
                  className="pointer"
                />
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Separate search term for lists
                />
              </div>
            </div>
            <div className="contactTable-outer">
              <div className="contact-table">
                <div className="table-body customscrollbar">
                  {dataLoader ? (
                    <div
                      className="d-flex align-items-center m-auto "
                      style={{ height: "100px" }}
                    >
                      <span className="spinner"></span>
                    </div>
                  ) : filteredData.length > 0 ? (
                    filteredData.map((item) => (
                      <div className="contact-detail" key={item._id}>
                        <div className="second">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.fileName}
                          </p>
                        </div>
                        <div className="third">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.lists.length}
                          </p>
                        </div>
                        <div className="forth">
                          <img
                            src="/assets/icons/delete.svg"
                            alt="Delete icon"
                            className="pointer"
                            onClick={() => {
                              setDeleteid(item._id);
                              handledeleteshow();
                            }}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="mx-auto py-4">No data</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Creating a New List */}
        <Modal
          size="lg"
          show={contactModal}
          centered
          onHide={() => {
            setContactModal(false);
            setContacts([]);
          }}
          className="contact-modal"
        >
          <Modal.Body>
            <h2 className="primarycolor Carmen-semibold-font text-center">
              Create new list
            </h2>
            <img
              src="/assets/icons/modalclose.svg"
              alt="Close"
              className="pointer close-btn"
              onClick={() => {
                setContactModal(false);
                setContacts([]);
              }}
            />
            <div className="contactsModal-inner">
              <div className="topbar">
                <div className="search-bar">
                  <img
                    src="/assets/icons/search.svg"
                    alt="Search icon"
                    className="pointer"
                  />
                  <input
                    type="text"
                    placeholder="Search here"
                    value={contactSearchTerm}
                    onChange={(e) => setContactSearchTerm(e.target.value)} // Separate search term for contacts
                  />
                </div>
                <Button
                  text="Upload CSV"
                  className="primary-button w-100 fs-16"
                  maxWidth="130px"
                  width="100%"
                  padding="8px 10px"
                  onClick={() => fileInput.click()} // Trigger file input
                />
                <input
                  type="file"
                  accept=".csv"
                  ref={(input) => setFileInput(input)}
                  style={{ display: "none" }}
                  onChange={handleFileInput}
                />
              </div>
              <div className="contactmodalTable-outer">
                <div className="contactmodal-table">
                  <div className="table-body customscrollbar">
                    <div className="contact-detail">
                      <div className="ist"></div>
                      <div className="second">
                        <h6 className="blackcolor Carmen-bold-font">Name</h6>
                      </div>
                      <div className="third">
                        <h6 className="blackcolor Carmen-bold-font">Number</h6>
                      </div>
                      <div className="forth">
                        <h6 className="blackcolor Carmen-bold-font">Email</h6>
                      </div>
                    </div>
                    {filteredContacts.map((item) => (
                      <div className="contact-detail" key={item.id}>
                        <div className="ist">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              if (e.target.checked) {
                                setContacts((prev) => [
                                  ...prev,
                                  {
                                    id: item?.id,
                                    name:
                                      item?.properties?.firstname +
                                      " " +
                                      item?.properties?.lastname,
                                    phone:
                                      item?.properties?.phone ||
                                      "+1 (415) 555‑0132",
                                    email:
                                      item?.properties?.email ||
                                      "user@gmail.com",
                                  },
                                ]);
                              } else {
                                setContacts(
                                  contacts.filter(
                                    (contact) => item.id !== contact.id
                                  )
                                );
                              }
                            }}
                            checked={contacts.some((contact) => {
                              return item.id === contact.id;
                            })}
                          />
                        </div>
                        <div className="second">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.properties.firstname +
                              " " +
                              item.properties.lastname}
                          </p>
                        </div>
                        <div className="third">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.properties.phone}
                          </p>
                        </div>
                        <div className="forth">
                          <p className="small-para greycolor Carmen-regular-font">
                            {item.properties.email}
                          </p>
                        </div>
                      </div>
                    ))}
                    {loading ? (
                      <span className="spinner mx-auto my-3"></span>
                    ) : filteredContacts.length < 1 ? (
                      <p className="mx-auto py-4">No data</p>
                    ) : (
                      <Button
                        text="Load more"
                        className="secondary-btn w-100 fs-16 mx-auto mt-3 bg-transparent"
                        maxWidth="120px"
                        padding="10px 10px"
                        onClick={fetchContacts}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Button
                text="Create"
                className="primary-button w-100 fs-16 ms-auto"
                maxWidth="98px"
                padding="10px 20px"
                onClick={handleListShow}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={csvModal}
          onHide={() => setCsvModal(false)}
          centered
          className="contact-modal"
        >
          <Modal.Body>
            <h2 className="primarycolor Carmen-semibold-font text-center">
              CSV Data
            </h2>
            <img
              src="/assets/icons/modalclose.svg"
              alt="Close"
              className="pointer close-btn"
              onClick={() => setCsvModal(false)}
            />
            <div className="contactsModal-inner">
              <div className="topbar">
                <div className="search-bar">
                  <img
                    src="/assets/icons/search.svg"
                    alt="Search icon"
                    className="pointer"
                  />
                  <input
                    type="text"
                    placeholder="Search here"
                    value={csvSearchTerm}
                    onChange={(e) => setCsvSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="contactmodalTable-outer">
                <div className="contactmodal-table">
                  <div className="table-body customscrollbar">
                    <div className="contact-detail">
                      <div className="ist"></div>
                      <div className="second">
                        <h6 className="blackcolor Carmen-bold-font">Name</h6>
                      </div>
                      <div className="third">
                        <h6 className="blackcolor Carmen-bold-font">Number</h6>
                      </div>
                      <div className="forth">
                        <h6 className="blackcolor Carmen-bold-font">Email</h6>
                      </div>
                    </div>
                    {filteredCsvData.map(
                      (item, index) =>
                        index != filteredCsvData.length - 1 && (
                          <div className="contact-detail" key={index}>
                            <div className="ist">
                              <input
                                type="checkbox"
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setContacts((prev) => [
                                      ...prev,
                                      {
                                        id: { index },
                                        name: item.Name || item.name || "N/A",
                                        phone:
                                          item.Phone ||
                                          item.phone ||
                                          "+1 (415) 555‑0132",
                                        email:
                                          item.Email ||
                                          item.email ||
                                          "User@gmail.com",
                                      },
                                    ]);
                                  } else {
                                    setContacts(
                                      contacts.filter(
                                        (contact) =>
                                          (item.Name || item.name) !==
                                          contact.name
                                      )
                                    );
                                  }
                                }}
                                checked={contacts.some(
                                  (contact) =>
                                    contact.name === (item.Name || item.name)
                                )}
                              />
                            </div>
                            <div className="second">
                              <p className="small-para greycolor Carmen-regular-font">
                                {item.Name || item.name || "N/A"}
                              </p>
                            </div>
                            <div className="third">
                              <p className="small-para greycolor Carmen-regular-font">
                                {item.Phone || item.phone || "N/A"}
                              </p>
                            </div>
                            <div className="forth">
                              <p className="small-para greycolor Carmen-regular-font">
                                {item.Email || item.email || "N/A"}
                              </p>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
                <Button
                  text="Create"
                  className="primary-button w-100 fs-16 ms-auto"
                  maxWidth="98px"
                  padding="10px 20px"
                  onClick={handleListShow}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Modal for Naming the List */}
        <Modal
          show={listName}
          onHide={() => {
            setListName(false);
          }}
          centered
          className="knowledgebase-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="Close"
              className="pointer close-btn"
              onClick={() => {
                setListName(false);
              }}
            />
            <h2 className="Carmen-semibold-font primarycolor">Add list name</h2>
            <div className="modal-inner">
              <Inputdash
                type="text"
                label="Add Name"
                placeholder="Type name here"
                name="fileName"
                onchange={(e) => {
                  setFileName(e.target.value);
                }}
              />
              <Button
                text="Add"
                className="primary-button w-100 fs-16"
                maxWidth="100px"
                padding="10px 16px"
                onClick={listApi}
                disabled={btnLoader ? true : false}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/* Delete list modal */}
        <Modal
          show={deletemodal}
          onHide={handledeleteclose}
          centered
          className="delete-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handledeleteclose}
            />
            <h3 className="Carmen-semibold-font primarycolor text-center">
              Are you sure you want to delete?
            </h3>
            <div>
              <Button
                text="Yes"
                className="secondary-btn fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={deleteList}
                disabled={loader}
              />
              <Button
                text="No"
                className="primary-button fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handledeleteclose}
              />
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
}

export default Contactslist;
